import { defineStore } from 'pinia'


export const useUserStore = defineStore({
    id: 'user',
    state: () => ({
        user: {},
        chats:[],
        isLoading:false,     
        baseUrl:`http://crm-demo.paramountstudents.com/`   
    }),
    getters : {
        getUser: (state) => {
            return state.user
        },
        getBaseUrl: (state) => {
            return state.baseUrl
        }
    },
    persist: true,
    actions: {
        setUser(userInfo) {
            this.user = userInfo
        },
        removeUser() {
            this.user = ""
        }
    }
})
