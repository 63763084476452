import { defineStore } from "pinia";
import {showModal} from "@/plugins/modal";
import { removeToken } from "@/utilities/authTokens";
import { useLocalStorage } from "@vueuse/core";

// import { fetchWrapper } from '@/helpers';
// import { router } from '@/router';
import { useUserStore } from "./user";
import { post } from "@/api/client";

// import { useAlertStore } from '@/stores/alert';

// const baseUrl = `${import.meta.env.VITE_API_URL}/users`;

export const useAuthStore = defineStore("auth", {
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    userId: 0,
    password: "",
    // session: useLocalStorage("session", {}),
    userInfo: useLocalStorage("userInfo", {}),
    userToken: useLocalStorage("userToken", null),
  }),
  getters: {},
  persist: true,
  actions: {
    setStoreToken(token) {
      this.userToken = token;
    },
    setUserInfo(user) {
      this.userInfo = user;
    },
    async login(unique_id, password) {
      try {
        const AuthenticatedUser = await post(
          "login_admin",
          {
            unique_id: unique_id,
            password: password,
          },
          "admin"
        );
        console.log(AuthenticatedUser);
        // const { status, user, authorisation } = AuthenticatedUser.data;
        if (AuthenticatedUser.status !== 200) {
          // alert(AuthenticatedUser.message);
          return false;
        }

        const user = AuthenticatedUser.data.user;
        this.$state.userInfo.fullName = `${user.first_name} ${user.last_name}`;
        this.$state.userInfo.email = `${user.email}`;
        this.$state.userInfo.src = `${user.profile_photo_url}`;
        // this.$state.session.id = unique_id;
        // this.$state.session.password = password;

        this.$state.password = AuthenticatedUser.password;
        this.$state.userId = unique_id;
        return AuthenticatedUser.data;
        /* 
        setToken(AuthenticatedUser.password);
        //update user in pinia state with result from server
        //this.setStoreToken()
        // store user token in local storage to keep user logged in between page refreshes

        // initialize user store and  update its state
        const userStore = useUserStore();
        userStore.setUser(AuthenticatedUser.user);
        

        if(AuthenticatedUser.status === 401) alert('expired token')

        // redirect to previous url or default to home page
        //return router.push("/dashboard");
        */
        // return (window.location.href = "/dashboard");
      } catch (error) {
        // const alertStore = useAlertStore();
        // alertStore.error(error);      
        showModal({text:error?.response?.message ?? "Incorrect Id or Password"});
        return false;
      }
      //   this.state.userToken = 1;
    },
    async signup(lastName, firstName, email, password, profilePic) {
      try {
        const AuthenticatedUser = await post(
          "register_admin",
          {
            last_name: lastName,
            first_name: firstName,
            email: email,
            password: password,
            profile_photo_url: profilePic,
          },
          "admin"
        );
        console.log(AuthenticatedUser);
      } catch (error) {
        // const alertStore = useAlertStore();
        // alertStore.error(error);        
        showModal({text:error?.response?.message ?? "Please fill the fields correctly"});
        return false;
      }
    },
    logout() {
      //set usertoken in pinia state to null
      this.userToken = null;
      const userStore = useUserStore();
      /*   console.log(userStore.getUser.email);
      console.log(this.userToken); */
      // try {
      // await get("logout_admin", "admin");
      userStore.removeUser();
      this.$state.userToken = null;
      removeToken();
      localStorage.clear();
      return (window.location.href = "/login");
      // } catch (err) {
      //   showModal({text:"There was an error logging out!"});
      //   console.log(err);
      // }

      //   if(logoutRequest.status == 401) alert('expired token')

      //remove token for local storage
      // removeToken();

      //redirect to login page
      // return (window.location.href = "/login");
    },
  },
});
