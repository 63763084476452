<template lang="html">

    <div class="d-flex sidenav flex-column flex-grow-1 align-items-center text-white sticky-top">
      <div class="align-items-center w-100">
        <div class="dashboard-nav h-auto py-3 ps-2">
          <div class="rounded-circle px-3">
            <img
              :src="require('/src/assets/images/logo.svg')"
              alt="logo"
              width="120"
            />
            <div class="pt-3">
              <div class="pt-0"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 overflow-auto dashboard-fixed">
        <div class="admin-wrapper bg-light flex-column flex-nowrap flex-shrink-1 flex-grow-1 mb-2 justify-content-center align-items-center">
        <div v-for="link in filteredLinks" @mouseenter="changeIconColor($event)" @mouseleave="neutralizeIconColor()" :key="link.id" :class="getLinkClasses(link)"  @click="handleLinkClick(link, $event,false)">
          <img v-svg-inline  :src="getIconPath(link)" alt="" :class="[' icon-size me-2']" :id="link.icon" />
          <span style="pointer-events: none;">{{ link.title }}</span>
          <span v-if="hasChildren(link)" class="sidebar-link-expand-icon">
            <i :class="['fas', { 'fa-rotate-90': isExpandedFunc(link) }]"></i>
          </span>
          <Transition name="slide-fade">
            <div v-if="isExpandedFunc(link)" class="w-100 py-0  nav nav-pills px-0 d-flex flex-column align-items-start mx-0 hovered expanded">
              <div v-for="innerLink in getInnerLinks()" :key="innerLink.id" @click="handleLinkClick(innerLink,$event, true)" :class="[{'innerActive': selectChildLinkId == innerLink.id}, 'position-relative text-light w-100 align-items-center d-flex btn nav nav-pills d-flex me-1 innerHovered justify-content-start']">
                <img v-svg-inline :src="require(`@/assets/images/d-board/${innerLink.img}`)" alt="" class="icon-size-child me-2" />
                <span style="pointer-events: none;">{{ innerLink.title }}</span>
                <span v-if="innerLink?.count" class="badge bg-danger text-white">{{ innerLink?.count }}</span>
              </div>
            </div>
          </Transition>
        </div>
      </div>
      </div>
      <div
        class="admin-wrapper mt-2 ms-3 w-100 flex-column flex-nowrap flex-shrink-1 flex-grow-1 mb-2 justify-content-center align-items-center"
      >
        <div @click="logout" class="btn nav nav-pills ms-2 d-flex hovered " :style="currentId === 12 && 'background-color:rgb(29, 41, 57) !important;color:white'" >
          <span  class="pe-2 d-flex align-items-center">
            <img v-show="currentId === 12"
              src="@/assets/images/d-board/logout-highlighted.svg"
              alt=""
              class="icon-size"
            />
            <img v-show="currentId !== 12"
              src="@/assets/images/d-board/logout.svg"
              alt=""
              class="icon-size"
              style="color: #fdb022"
            />
          </span>          
          <span style="font-size: 12px !important"> Logout </span>
        </div>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth";
import { get } from "@/api/client";
import jquery from 'jquery';
const $ = jquery;
export default {
  name: "SideBar",
  data() {
    return {
      currentId: null,
      expandedLinkId: null,
      selectChildLinkId:null,
      links: [  
      {
        id: 1,
        title: "Dashboard",
        permission:"can_view_dashboard",
        icon: "studentCourses-highlighted.svg",
        imgBlack: "studentCourses.svg",
        linkRoute: "/dashboard",
        children: []
      },
      {
        id: 2,
        title: "Universities List",
        permission:"can_view_university",
        icon: "universitiesList-highlighted.svg",
        imgBlack: "universitiesList.svg",
        linkRoute: "/universities-list",
        children: []
      },
      {
        id: 3,
        title: "High School List ",
        permission:"can_view_high_school",
        icon: "highSchoolList-highlighted.svg",
        imgBlack: "highSchoolList.svg",
        linkRoute: "/high-schools-list",
        children: []
      },
      {
        id: 4,
        title: "University Program",
        permission:"can_view_programs",
        icon: "universityProgrammes-highlighted.svg",
        imgBlack: "universityProgrammes.svg",
        linkRoute: "/programs",
        children: []
      },
      {
          id: 5,
          title: "Highschool Associations",
          permission: "can_view_highschool_associations",
          icon: "universitiesList-highlighted.svg",
          imgBlack: "universitiesList.svg",
          linkRoute: "/highschool-associations",
          children: []
        },     
      {
        id: 6,
        title: "Students Application",
        permission:"can_view_student_application",
        icon: "studentsApplication-highlighted.svg",
        imgBlack: "studentsApplication.svg",
        linkRoute: "/high-school-application",
        children: []
      },
      {
        id: 7,
        title: "Promotions",          
        icon: "promotions-highlighted.svg",
        imgBlack: "promotions.svg",
        linkRoute: "/promotions",
        children: []
      },
     
      {
        id: 8,
        title: "Manage Country",
        permission:"can_chats_high_school",
        img: "chat-highlighted.svg",
        icon: "chat.svg",
        count:0,
        linkRoute: "/countries",
        children: []
      },  
      {
        id: 9,
        title: "Blogs",        
        icon: "chat-highlighted.svg",
        permission:"can_chats_high_school",
        imgBlack: "chat-highlighted.svg",
        linkRoute: "/blog",
        children: []
      },
      {
        id: 10,
        title: "Users and Roles",
        permission:"can_view_manage_users",
        icon: "usersAndRoles-highlighted.svg",
        imgBlack: "usersAndRoles.svg",
        linkRoute: "/users-and-roles",
        children: []
      },
      {
        id: 11,
        title: "Live Chat",
        permission:"can_chats",
        icon: "chat-highlighted.svg",
        imgBlack: "chat.svg",
        linkRoute: "/chats",
        children: [
        {
          id: 112,
          title: "University Chat",
          permission:"can_chats_university",
          img: "chat-highlighted.svg",
          icon: "chat.svg",
          count:this.$globals.chats.university.length,
          linkRoute: "/chat/university",
          children: []
        },
        {
          id: 122,
          title: "High School Chat",
          permission:"can_chats_high_school",
          img: "chat-highlighted.svg",
          icon: "chat.svg",
          count:this.$globals.chats.highschool.length,
          linkRoute: "/chat/high-school",
          children: []
        }
        ]
      },
      
      {
        id: 11,
        title: "Support",
        permission:"can_support",
        icon: "mails-highlighted.svg",
        imgBlack: "mails.svg",
        linkRoute: "/help-and-support",
        children: []
      },
    ],
    };

  },
  watch:{
    
    '$globals.chats':{
      deep: true,
      handler(newChats) {
        
        this.links[10].children[0].count = newChats.university.length;
        this.links[10].children[1].count = newChats.highschool.length;
      }
    }
  },
  methods: {
    changeFill(link) {
      const image = document.getElementById(link.icon)           
      const svgElement = image.querySelector('path');
      console.log(svgElement)
      svgElement.setAttribute('fill', 'white');
    },
    getIconPath(link) {
      try {
        return require(`@/assets/images/d-board/${link.imgBlack}`);
      } catch (error) {   
        return require('@/assets/images/d-board/universitiesList.svg');
      }
    },
    getLinkClasses(link) {
      return ['btn nav nav-pills nav-pills-main my-0  mt-1 ms-3 me-1 hovered justify-content-start',{
        'activeBtn': this.isActive(link),    
      }];
    },
    hasChildren(link) {
      return link.children && link.children.length > 0;
    },
    isExpandedFunc(link) {
      return this.expandedLinkId === link.id;
    },
    getInnerLinks() {
      const expandedLink = this.links.find(link => link.id === this.expandedLinkId);
      return expandedLink ? expandedLink.children : [];
    },
    isActive(link) {
      return this.currentId === link.id;
    },
    isActiveChild(link) {            
      return this.selectChildLinkId === link.id;
    },
    hasPermission(permission) {  
      return permission; // Return true for now, replace with actual implementation
    },
    async handleLinkClick(link, e, child) {
      e.stopPropagation();
      if (this.hasChildren(link)) {                
        this.expandedLinkId = link.id;
        this.selectChildLinkId = link.children[0].id;
        this.currentId = link.id;
      } else {         
        if(!child){
          this.expandedLinkId = ''
          this.currentId = link.id;
        } else if(child){         
          this.selectChildLinkId = link.id;
        }
        this.displayView(link.id, link.linkRoute);
      }
      

      if (!child) {
        this.changeIconColor(e);
      } else {
        if (!$('svg.icon-size-child path')) return true;
        await $('svg.icon-size-child path').each(function () {
          $(this).css('fill', 'white');
        });
        $(e.target).find('svg.icon-size-child path').css('fill', '#ffc107');
      }  
},
  async neutralizeIconColor(){
  if (!$('svg.icon-size path')) return true;
    await $('svg.icon-size').each(function () {      
      if (!$(this).parent().hasClass('activeBtn')) {
        $(this).find("path").each(function(){
          $(this).css('fill', '#1D2939');
        })
      }
    });
},
changeIconColor(e,state=true){
    this.neutralizeIconColor()
    if(state){
      $(e.target).find('svg path').css('fill', '#ffc107');
    }
},
updateUnreadChats(){      
      this.links = this.links.map((item, index) => {
  if (index === 9) {
    const highSchoolUnreadCount = this.chats?.highSchool?.reduce(
      (sum, item) => sum + Number(item.total_unread),
      0
    );
    item.count = highSchoolUnreadCount;
  }
  if (index === 10) {
    const universityUnreadCount = this.chats?.university?.reduce(
      (sum, item) => sum + Number(item.total_unread),
      0
    );
    item.count = universityUnreadCount;
  }
  return item;
});

    },
    expand() {
      this.isExpanded = !this.isExpanded;
    },
    displayView(linkId, link) {
      let value = { linkId, link };      
      this.handleDisplayText(value);
    },
   
    handleDisplayText(value) {      
      let linkId, link;
      [linkId, link] = Object.values(value);
      linkId;    
      this.linkRoute = link;          
      this.$router.push(`${this.linkRoute}`);
      
    },
    setting() {
      this.$router.push("/settings");
    },
    getLocation() {
      let location = window.location.pathname.split("/")[1];
      let linkId = this.links.find((el) => el.linkRoute === `/${location}`);
      this.currentId = linkId != undefined ? linkId.id : null;
      return linkId;
    },
    checkRoute(fullPath) {
      if (
        fullPath == "/dashboard" ||
        fullPath == "/universities-list" ||
        fullPath == "high-school-list"
      ) {
        return true;
      }
    },
    logout() {
      console.log("Got Here");
      const authStore = useAuthStore();
      authStore.logout();
    },
  },
  computed: {
    filteredLinks() {
      return this.links.filter(link => this.hasPermission(link.permission));
    },
    animateClass() {
      return !this.animate;
    },
  },
  
  beforeMount() {
    const authStore = useAuthStore();
    this.userInfo = authStore.$state.userInfo;
  },
  mounted() {
    this.getLocation();          
  },
  beforeRouteEnter(to, from, next) {        
    next();
  },
  async created() {
    this.permissions = JSON.parse(localStorage.getItem('userInfo'))?.all_permissions?.map(permission => permission.name)
    let  res = await get('chat','admin')        
    res = res?.message
    this.chats = res?.chats        
    this.currentRoute = this.$route.fullPath
  },  
};
</script>
<style scoped lang="scss">
.nav-pills-main:hover > .nav-pills > .innerHovered >span, .nav-pills-main:hover > svg>  path{
  color:white !important;
  fill:white !important;
}

.messageSpan {
  width: 20px;
  height: 20px;
  text-align: center;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;

  border-radius: 8px;
  background-color: #f0ad4e;
  color: black;
}
.innerHovered:hover > .messageSpan {
  background-color: #1d2939;
  color: white;
}
@keyframes expand {
  0% {
    transform: translateX(-25px);
    opacity: 0;
  }
  40% {
    opacity: 1;
    
  }
  100% {
    transform: translateX(0px);    
  }
}
.icon-size{
  width:23px !important;
}
.icon-size:hover >path{
  fill: white !important;
}
.expanded {
  /* transform-origin: top;
  animation-name: expand;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1; */
}
.dashboard-fixed::-webkit-scrollbar,
.sidenav::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
.nav-pills:last-child {
  margin-top: 4px !important;
  padding: 10px !important;
}
.sidenav::-webkit-scrollbar {
  background-color: #aaa;
}
.sidenav::-webkit-scrollbar-thumb {
  background: #000;
}

.p-tag {
  font-size: 10px !important;
  background-color: transparent !important;
  color: black;
  padding-top: 5px;
}
.hovered{
  border-radius: 8px;
}
.hovered:hover {
  background: #1d2939;
  border-radius: 8px;
  color: #fff;
}
.activeBtn{
  background: #1d2939;
  border-radius: 8px;
  color: #fff;
}
/* .activeBtn .hovered {
  background: #1d2939;
} */

.innerActive {
  background: #81818182  !important;
  border-radius: 8px;
  color: #fff;
}

.innerHovered:hover {
  background: #f0ad4e;
  border-radius: 8px;
  color: #fff;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>