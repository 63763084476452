
import Echo from 'laravel-echo';

const initializeEcho = (baseUrl,accessToken) => {
  const urlObject = new URL(baseUrl);
  const hostname = urlObject.hostname;

  window.Pusher = require('pusher-js');
  window.Echo = new Echo({    
    authEndpoint: `${baseUrl}admin/broadcasting/auth`,
    broadcaster: 'pusher',
    key: 'localapp', //import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: hostname, //import.meta.env.VITE_PUSHER_HOST,
    wsPort: 6001, //import.meta.env.VITE_PUSHER_PORT,
    disableStats: true,
    cluster: 'mt1',
    forceTLS: false,
    auth: {
      headers: {
        'Authorization': 'Bearer ' +accessToken
      }
    }
  });
};

export default initializeEcho;