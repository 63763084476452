<template>
  <!--    <div id="app">-->
    <!--    </div>-->
  <!--        <router-view />-->
  <div>
    <RequestLoader />
    <router-view />
  </div>
</template>
<script>
import RequestLoader from '@/components/Global/RequestLoader';    
export default {
  components: { RequestLoader },
  methods: {
    async tryS3() {
     
    },
  },  
  unmounted() {
    // localStorage.clear();
  },
};
</script>
